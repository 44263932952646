import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { CurrentNavigationExtra } from '../../decorators/current-navigation-extra.decorator';
import { CurrentUser } from '../../decorators/current-user.decorator';
import { IsDevice } from '../../decorators/is-device.decorator';
import { IsLoggedIn } from '../../decorators/is-logged-in.decorator';
import { IsSidenavMouseOver } from '../../decorators/is-sidenav-mouse-over.decorator';
import { Post } from '../../domains/post';
import { User } from '../../domains/user';
import { IDeactivateComponent } from '../../interfaces/ideactivate.component';
import { BooleanLocalStorage } from '../../local-storage/boolean-local-storage';
import { Role } from '../../models';
import { DeviceType } from '../../models/enums/device-type';
import { LocalStorageKey } from '../../models/enums/local-storage-key';
import { ModalType } from '../../models/enums/modal-type';
import { Paths } from '../../models/enums/paths';
import { SidenavType } from '../../models/enums/sidenav-type';
import { Image } from '../../models/image';
import { AuthenticationService } from '../../services/authentication.service';
import { BackgroundImageService } from '../../services/background-image.service';
import { CheckoutService } from '../../services/checkout.service';
import { ImgixService } from '../../services/imgix.service';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';
import { NotificationService } from '../../services/notification.service';
import { PostService } from '../../services/post.service';
import { SeoService } from '../../services/seo.service';
import { UserService } from '../../services/user.service';
import { WebSocketService } from '../../services/web-socket.service';
import { ImageUtils } from '../../utils/image-utils';
import { PostListener } from '../../websocket/listeners/post-listener';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: [
    './user.component.scss',
    '../navbar/navbar.shared.scss'
  ]
})
export class UserComponent implements OnInit, IDeactivateComponent {
  @ViewChild('shareModalRef') shareModalRef: ElementRef;
  @ViewChild('membership') membershipModalRef: ElementRef;
  @ViewChild('postDesktopModalRef') postDesktopModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  @CurrentNavigationExtra('post', {classType: Post}) newPost: Post;

  @IsLoggedIn() isLoggedIn: boolean;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  private page = 0;

  user: User;
  paths = Paths;
  role = Role;
  isProfileImageLoadError = false;
  isImageUploading = false;
  posts: Post[] = [];
  shareModalPath: Paths;
  loaderWasHide = false;
  hasNext = true;
  requestSent = false;
  isSubscribed = false;
  currentPost: Post;

  constructor(
    public authenticationService: AuthenticationService,
    private navigatorService: NavigatorService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private logger: NGXLogger,
    private postService: PostService,
    private webSocketService: WebSocketService,
    private _location: Location,
    @Inject(DOCUMENT) private _document: Document,
    private loaderService: LoaderService,
    private imgixService: ImgixService,
    private notificationService: NotificationService,
    private checkoutService: CheckoutService,
    private seoService: SeoService,
    private backgroundImageService: BackgroundImageService,
  ) {
  }

  ngOnInit() {
    if (!this.isMobile) {
      this.backgroundImageService.setCurrentBackgroundImageForDesktop();
    }

    setTimeout(() => {
      if (!this.loaderWasHide) {
        this.loaderService.showLoader();
      }
    }, 100);

    this.activatedRoute.params.subscribe(params => {
      if (this.currentUser.username === params['username']) {
        this.user = this.currentUser;

        this.initData();
      } else {
        this.userService.getByUsername(params['username']).subscribe((user: User) => {
          this.user = user;

          this.initData();
        });
      }

      this.webSocketService.registerListener(new PostListener(params['username'])).subject
        .subscribe((post: Post) => {
          const index = this.posts.findIndex(iPost => iPost.hash === post.hash);

          if (index === -1) {
            this.posts.unshift(post);
          } else {
            this.posts[index].id = post.id;

            ImageUtils.downloadImage(this.imgixService.getUrl(post.images[0].path, {w: 720}))
              .then(() => {
                this.posts[index].images[0].path = post.images[0].path;
              });
          }
        });
    });
  }

  get isOwner(): boolean {
    if (!this.user) {
      return false;
    }

    return this.currentUser?.id === this.user.id;
  }

  onShareClick(path: Paths) {
    this.shareModalPath = path;

    this.modalService.open(this.shareModalRef, ModalType.FULL_TRANSPARENT);
  }

  onShareClickDesktop() {
    this.notificationService.followsDesktop();
  }

  private initData() {
    this.seoService.setUserMetaTags(this.user);

    this.checkoutService.isUserSubscribed(this.user).subscribe(value => {
      this.isSubscribed = value;
    });

    this.posts = [];
    this.page = 0;

    const checkoutLocalStorage = new BooleanLocalStorage(LocalStorageKey.CHECKOUT);

    if (checkoutLocalStorage.getItem()) {
      setTimeout(() => {
        this.notificationService.checkoutSuccess();

        this.openMembershipModal();
      }, 1000);

      checkoutLocalStorage.removeItem();
    }

    this.getActivities().then(() => {
      this.activatedRoute.queryParams.subscribe(queryParams => {
        const postHash = queryParams['post'];

        if (postHash) {
          this.currentPost = this.posts.find(post => post.hash === postHash);

          if (this.currentPost) {
            if (!this.isMobile) {
              this.modalService.open(this.postDesktopModalRef, ModalType.POST_SQUARE);
            } else {
              this.navigatorService.goToUserPost(this.user.username, this.currentPost, this.posts);
            }
          }
        }
      });
    });
  }

  private getActivities(): Promise<void> {
    return new Promise((resolve) => {
      this.requestSent = true;

      if (!this.user.getstreamToken) {
        this.loaderWasHide = true;
        this.loaderService.hideLoader();

        resolve();
      } else {
        this.postService
          .getPostsByUsername(this.user.username, this.page)
          .subscribe((postPage) => {
            this.hasNext = postPage.hasNext;
            this.page++;

            this.posts = this.posts.concat(postPage.posts);

            setTimeout(() => {
              this.requestSent = false;

              resolve();
            }, 100);

            this.loaderWasHide = true;
            this.loaderService.hideLoader();
          });
      }
    });
  }

  onImageClick() {
    if (!this.isOwner) {
      this.onShareClick(this.paths.MESSAGE);
    }
  }

  onImageUploaded(image: Image) {
    this.loaderService.hideLoader();

    this.isImageUploading = true;
    this.isProfileImageLoadError = false;

    this.userService.uploadProfileImage(image).subscribe({
      next: (user: User) => {
        this.user.profileImage = user.profileImage;
        this.authenticationService.currentUser = this.user;
      }, error: error => {
        this.isProfileImageLoadError = true;
        this.logger.error(error);
      }
    })
    .add(() => {
      this.isImageUploading = false;
    });
  }

  goBack() {
    this._location.back();
  }

  goToPost(post: Post) {
    this.navigatorService.goToUserPost(this.user.username, post, this.posts, this.page, this.hasNext);
  }

  goToPostDesktop(post: Post) {
    this.currentPost = post;
    this.modalService.open(this.postDesktopModalRef, ModalType.POST_SQUARE);
  }

  goToPostNew(image: Image) {
    this.navigatorService.goToUserPostNew(image);
  }

  openMembershipModal() {
    this.modalService.open(this.membershipModalRef, ModalType.MEMBERSHIP);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.newPost) {
      setTimeout(() => {
        this._document.defaultView.location.reload();
      }, 10);
    }

    return !this.newPost;
  }

  onScroll() {
    if (!this.requestSent && this.hasNext) {
      this.getActivities().then();
    }
  }
}
