import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swiper, { SwiperOptions, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper_angular';
import { AppService } from '../../../app.service';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentNavigationExtra } from '../../../decorators/current-navigation-extra.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Food } from '../../../domains/food';
import { Post } from '../../../domains/post';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { GetstreamVerb } from '../../../models/enums/getstream-verb';
import { Paths } from '../../../models/enums/paths';
import { NavigatorService } from '../../../services/navigator.service';
import { PostService } from '../../../services/post.service';
import { SeoService } from '../../../services/seo.service';
import { ShareService } from '../../../services/share.service';

Swiper.use([Virtual]);

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: [
    './../posts.component.scss'
  ]
})
export class PostsComponent implements OnInit, OnDestroy {
  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @ViewChild('swiper', {static: false}) swiper?: SwiperComponent;

  @CurrentUser() currentUser: User;

  @CurrentNavigationExtra('posts', {classType: Post}) postsFromState: Post[];
  @CurrentNavigationExtra('food', {classType: Food}) food: Food;
  @CurrentNavigationExtra('hasNext') hasNext: boolean;
  @CurrentNavigationExtra('page') page: number;

  restaurantPath: string;
  restaurantTitle: string;
  currentPost: Post;
  posts: Post[] = [];
  captionEdited: string;
  captionExpanded = false;
  editMode = false;
  reportText = '';
  getstreamVerb = GetstreamVerb;
  index = 0;
  isFanFoodImage: boolean;
  postsUrl: string;
  paths = Paths;
  hideCaption = false;
  isSubscribed = false;
  username: string;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    direction: 'vertical',
    preloadImages: false,
    observer: true,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private postService: PostService,
    private navigatorService: NavigatorService,
    private shareService: ShareService,
    private router: Router,
    private seoService: SeoService,
    private appService: AppService,
    private ngZone: NgZone
  ) {
    this.isFanFoodImage = this.activatedRoute.snapshot.data['isFanFoodImage'];
  }

  ngOnInit() {
    this.appService.themeColor = 'black';

    this.activatedRoute.params.subscribe(params => {
      const postHash = params['postHash'];

      if (!this.isMobile) {
        this.navigatorService.goToUserPostDesktop(this.currentUser.username, postHash);

        return;
      }

      this.postsUrl = this.router.url.split(`${!this.isFanFoodImage ? '/post' : ''}/${postHash}`)[0];

      this.username = params['username'];

      this.setPosts(this.postsFromState, postHash);
    });
  }

  ngOnDestroy(): void {
    this.appService.themeColor = 'white';
  }

  private setPosts(posts: Post[], postHash: string) {
    this.index = -1;

    if (posts) {
      this.posts = [];

      posts.forEach(post => {
        if (post.images.length > 0) {
          this.posts.push(post);
        }
      });

      this.index = this.posts.findIndex(postI => postI.hash === postHash);
    }

    if (this.index === -1) {
      this.postService.getPost(postHash)
        .subscribe({
          next: value => {
            this.posts.push(value);
            this.index = this.posts.length - 1;
            this.setCurrentPost(this.posts[this.index]);
          }, error: _ => {
            this.goToBack();
          }
        });
    } else {
      this.setCurrentPost(this.posts[this.index]);
    }

    if (this.swiper) {
      this.swiper.swiperRef.slideTo(this.index);
      history.back();
    }
  }

  private setCurrentPost(post: Post) {
    this.restaurantPath = post.restaurantPath;
    this.restaurantTitle = post.restaurantTitle;

    this.seoService.setPostMetaTags(post);

    this.currentPost = post;
    this.captionEdited = this.currentPost.caption;
  }

  goToUser(username) {
    this.navigatorService.goToUser(username);
  }

  rightButtonClick() {
    if (this.editMode) {
      this.currentPost.caption = this.captionEdited;

      this.postService.editPost(this.currentPost)
        .subscribe(() => {
          this.editMode = false;
        });
    }
  }

  cancelMode() {
    this.editMode = false;
    this.captionEdited = this.currentPost.caption;
    this.reportText = '';
  }

  @Auth()
  onShareClick() {
    this.shareService.sharePost(this.currentPost, this.currentUser);
  }

  setFocus(editor: any) {
    editor.focus();
  }

  slideChange(event: any) {
    this.ngZone.run(() => {
      const swiper: Swiper = event[0];

      this.index = swiper.activeIndex;

      this.setCurrentPost(this.posts[this.index]);

      if (this.posts.length - 3 <= this.index) {
        if (this.isFanFoodImage) {
          if (this.hasNext) {
            this.postService.getPostsByFoodId(this.food.id, this.page).subscribe(value => {
              this.page++;
              this.hasNext = value.hasNext;

              this.posts = this.posts.concat(value.posts);
            });
          }
        } else if (this.hasNext) {
          this.postService.getPostsByUsername(this.username, this.page).subscribe(value => {
            this.page++;
            this.hasNext = value.hasNext;

            this.posts = this.posts.concat(value.posts);
          });
        }
      }

      this.navigatorService.changeLocationTo(
        `${this.postsUrl}${!this.isFanFoodImage ? '/post' : ''}/${this.currentPost.hash}`
      );
    });
  }

  goToBack() {
    this.navigatorService.goToUrl(this.postsUrl).then();
  }

  postEdit() {
    this.editMode = true;
  }

  postDeleted() {
    this.navigatorService.goToUser();
  }

  goToRestaurant() {
    this.navigatorService.goToUrl(this.restaurantPath).then();
  }

  getTitle() {
    let title = '';

    if (this.currentPost.food) {
      title += this.currentPost.food.title + ' • ';
    }

    title += this.restaurantTitle + ' • ' + this.currentPost.restaurantCityState;

    return title;
  }
}
