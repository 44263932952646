import { Injectable } from '@angular/core';
import { NavigatorService } from './navigator.service';
import Timeout = NodeJS.Timeout;

@Injectable({providedIn: 'root'})
export class GuardService {
  private redirectsCount = 0;
  private timeout: Timeout;

  constructor(private navigatorService: NavigatorService) {
  }

  /**
   * Prevents endless redirects
   */
  redirectWithLimit(url: string, callback: () => void = null) {
    const MAX_REDIRECTS_COUNT = 16;
    const MAX_REDIRECTS_PERIODS = 1000;

    if (this.timeout === undefined) {
      this.timeout = setTimeout(() => {
        this.redirectsCount = 0;
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }, MAX_REDIRECTS_PERIODS);
    }

    if (this.redirectsCount < MAX_REDIRECTS_COUNT) {
      this.navigatorService.goToUrlPlain(url).then(() => {
        this.redirectsCount++;

        if (callback) {
          callback();
        }
      });
    }
  }
}
